import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import * as React from 'react';
import Layout from '../components/layout';
import ContactContent from '../components/organisms/ContactContent';
import PageContent from '../components/organisms/PageContent';
import SEO from '../components/seo';

const Kontakt: React.FC = () => {
    const {t} = useTranslation();
    
    return ( 
        <Layout>
            <SEO title={t('contact.title')} description={t('contact.subtitle')}/>
            <PageContent>
              <ContactContent title={t('contact.title')} subtitle={t('contact.subtitle')}/>
            </PageContent>
        </Layout>
     );
}

export const pageQuery = graphql`
query($language: String!) {
  locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
}`
 
export default Kontakt;
