import React from 'react';

export type LocalizationType = {
    city: string;
    address: string | React.ReactNode;
    fax: string;
    email: string;
};

export const AllLocalizations: LocalizationType[] = [
    {
        city: "Warszawa",
        address: <>ul. Bitwy Warszawskiej 1920r. 7<br/>02-366 Warszawa</>,
        fax: "+48 515 285 776",
        email: "kontakt@databout.pl"
    },
    {
        city: "Kraków",
        address: <>ul. Stefana Batorego 26/11<br/>31-135 Kraków</>,
        fax: "+48 515 285 776",
        email: "kontakt@databout.pl"
    },
    {
        city: "Katowice",
        address: <>ul. Krasińskiego 29<br/>40-019 Katowice</>,
        fax: "+48 515 285 776",
        email: "kontakt@databout.pl"
    },
    {
        city: "Rzeszów",
        address: <>ul. Armii Krajowej 80<br/>35-307 Rzeszów</>,
        fax: "+48 515 285 776",
        email: "kontakt@databout.pl"
    },
]
