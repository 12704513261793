import * as React from 'react';
import styled from 'styled-components';
import { useState } from 'react';
import Dropdown from '../atoms/Dropdown';
import ContactCityInfo from '../molecules/ContactCityInfo';
import ContactMapWrapper from '../molecules/ContactMapWrapper';
import { AllLocalizations, LocalizationType } from '../../AllLocalizations';

type ContactContentProps = {
    title: string;
    subtitle: string;
}

const ContactContentWrapper = styled.div`	
    display: flex;
    align-items: center;
    padding: ${({ theme }) => theme.paddingDesktop}; 
    padding-top: 216px;
    padding-bottom: 0px;
    flex-wrap: wrap;

  
    @media (max-width: 767.98px) {
        padding: ${({ theme }) => theme.paddingPhone};
        padding-top: 150px;
        flex-direction: column-reverse;
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
        padding: ${({ theme }) => theme.paddingTablet}; 
        padding-top: 200px;
    }

    @media (min-width: 992px) and (max-width: 1199.98px) { 
        padding: ${({ theme }) => theme.paddingLaptop}; 
        padding-top: 200px;
    }

    @media (min-width: 1200px) and (max-width: 1600px) {
        padding: ${({ theme }) => theme.paddingLaptop}; 
        padding-top: 170px;
    }
`;

const ContactInfo = styled.div`	
    display: flex;
    flex-direction: column;
    width: 50%;

    @media (min-width: 1px) and (max-width: 991.98px) {
         width: 100%;
         margin-bottom: 40px;
    }
`;

const Title = styled.h1`
	width: 100%;
	color: ${({ theme }) => theme.primaryColor};
	margin: 0 0 50px 0;
    font-weight: 600;
    font-size: ${({ theme }) => theme.fontSize.h2}; 
    line-height: 60px;
`;

const Subtitle = styled.h2`
	width: 100%;
	margin: 0 0 65px 0;
    font-weight: 300;
    font-size: ${({ theme }) => theme.fontSize.h4}; 
    line-height: 1.4;
`;

const StyledDropdown = styled(Dropdown)`
	margin: 0;
`;


const ContactContent: React.FC<ContactContentProps> = ({title, subtitle}) => {

    const [activeLocalization, setActiveLocalization] = useState<LocalizationType | string>("Warszawa");
    const AllCities = Array.from(AllLocalizations.map(item => item.city));

    return ( 
        <ContactContentWrapper>
            <ContactInfo>
                <Title>{title}</Title>
                <Subtitle>{subtitle}</Subtitle>
                <StyledDropdown isWhiteBackground={true} options={AllCities} handleDropdown={(option: string) => setActiveLocalization(option)} activeOption={activeLocalization}/>
                {
                    AllLocalizations.filter(item => item.city === activeLocalization).map((item, index) => 
                    <ContactCityInfo key={index} city={item.city} address={item.address} fax={item.fax} email={item.email} />)
                }
            </ContactInfo>
            <ContactMapWrapper activeLocalization={activeLocalization} setActiveLocalization={(option: string) => setActiveLocalization(option)} cities={AllCities}/>
        </ContactContentWrapper>
    );
}
 
export default ContactContent;