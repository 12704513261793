import React, { useEffect } from 'react';
import styled from 'styled-components';
import { LocalizationType } from '../../AllLocalizations';
import ContactMap from '../../assets/contact-map.svg';

type ContactMapWrapperProps = {
    activeLocalization: LocalizationType | string;
    setActiveLocalization: (option: string) => void;
    cities: string[];
}

const StyledContactMapWrapper = styled.div`	
    display: flex;
    justify-content: flex-end;
    width: 50%;

    .map {
        max-width: 808px;
        max-height: 678px;
    }

    .city {
        cursor: pointer;
        :hover {
            .cityDotBorder {
                stroke: ${({ theme }) => theme.blueColor};
            } 
            .cityDot {
                fill: ${({ theme }) => theme.blueHoverColor};
            }
        }
    } 

    .Warszawa--active {
        .Warszawa {
            animation: pulse 2s infinite;
                transform-origin: 50% 50%;
                transform-box: fill-box;
            .cityDotBorder {
                stroke: ${({ theme }) => theme.blueColor};
            } 
            .cityDot {
                fill: ${({ theme }) => theme.secondaryColor};
            }
        }
    }

    .Kraków--active {
        .Kraków {
            animation: pulse 2s infinite;
                transform-origin: 50% 50%;
                transform-box: fill-box;
            .cityDotBorder {
                stroke: ${({ theme }) => theme.blueColor};
            } 
            .cityDot {
                fill: ${({ theme }) => theme.secondaryColor};
            }
        }
    }

    .Rzeszów--active {
        .Rzeszów {
            animation: pulse 2s infinite;
                transform-origin: 50% 50%;
                transform-box: fill-box;
            .cityDotBorder {
                stroke: ${({ theme }) => theme.blueColor};
            } 
            .cityDot {
                fill: ${({ theme }) => theme.secondaryColor};
            }
        }
    }

    
    .Katowice--active {
        .Katowice {
            animation: pulse 2s infinite;
                transform-origin: 50% 50%;
                transform-box: fill-box;
            .cityDotBorder {
                stroke: ${({ theme }) => theme.blueColor};
            } 
            .cityDot {
                fill: ${({ theme }) => theme.secondaryColor};
            }
        }
    }

    @keyframes pulse {
        0% {
            transform: scale(0.9);
        }

        50% {
            transform: scale(1.1);
        }

        100% {
            transform: scale(0.9);
        }
    }

    @media (max-width: 991.98px) {
         width: 100%;
         margin-bottom: 60px;
    }
`;

const ContactMapWrapper: React.FC<ContactMapWrapperProps> = ({activeLocalization, setActiveLocalization, cities}) => {

    useEffect(() => {
        cities.map(city => {
            document.querySelector(`.${city}`).addEventListener('click', function(event) {
                event.preventDefault();
                setActiveLocalization(city);
            })
        })
    }, [])

    return ( 
        <StyledContactMapWrapper>
            <ContactMap className={`${activeLocalization}--active map`}/>
        </StyledContactMapWrapper>
     );
}

export default ContactMapWrapper;
