import { useTranslation } from 'gatsby-plugin-react-i18next';
import * as React from 'react';
import Popup from 'reactjs-popup';
import styled from 'styled-components';
import Button from '../atoms/Button';
import SendMessagePopUp from '../organisms/SendMessagePopUp';

type ContactCityInfoProps = {
    city: string,
    fax: string,
    email: string,
    address: string | React.ReactNode,
}

const StyledButton = styled(Button)`
	margin: 20px 0 0 0;
    width: 256px;
`;

const CityAnchor = styled.a`
	width: 100%;
    margin: 0;
    text-decoration: none;
    color: ${({ theme }) => theme.blackColor};
    font-family: 'Museo Sans';
    font-weight: 300;
    font-size: 18px;
    line-height: 160%;

    :hover {
        color: ${({ theme }) => theme.primaryColor};
    }
`;

const CityTitle = styled.h3`
	width: 100%;
	color: ${({ theme }) => theme.primaryColor};
	margin: 53px 0 32px 0;
`;

const CityAddress = styled.span`
	width: 100%;
    margin: 0 0 32px 0;
`;

const StyledContactCityInfo = styled.div`
	display: flex;
    flex-direction: column;
`;
 
const ContactCityInfo: React.FC<ContactCityInfoProps> = ({ city, address, fax, email }) => {
    const {t} = useTranslation();
    
    return (
        <StyledContactCityInfo>
            <CityTitle>{city}</CityTitle>
            <CityAddress>{address}</CityAddress>
            <CityAnchor href={`fax:${fax.replace(/\s+/g, '')}`}>{fax}</CityAnchor>
            <CityAnchor href={`mailto:${email}`}>{email}</CityAnchor>
            <Popup trigger={<StyledButton type="blue">{t('sendMessage')}</StyledButton>} modal>
             { close => <SendMessagePopUp email={email} close={close}/>}
            </Popup>
        </StyledContactCityInfo>
     );
}
 
export default ContactCityInfo;